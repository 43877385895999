import React from 'react';
import NavBar from 'js/components/NavBar';

export default () => {
    return (
        <div style={{ marginBottom: '-1.45em' }}>
            <NavBar noLogoLink />
        </div>
    );
};
