import React from 'react';
import PropTypes from 'prop-types';
import { NavBar, NavLink } from 'cccisd-laravel-boilerplate';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    profileDataFields = {
        learner: ['bacb_cert_num'],
    };

    rolesWithManage = ['uberadmin'];

    render() {
        return (
            <NavBar
                {...this.props}
                hideSeparateRoleSwitcher
                logo={<div className={style.logo} />}
                showTheseProfileDataFields={this.profileDataFields}
            >
                {Fortress && Fortress.auth() && this.rolesWithManage.includes(Fortress.user.acting.role.handle) && (
                    <NavLink to="/manage">Manage</NavLink>
                )}
                {Fortress && Fortress.auth() && Fortress.user.acting.role.handle === 'questprojectadmin' && (
                    <NavLink to="/quest">Quest</NavLink>
                )}
            </NavBar>
        );
    }
}
